import React, {useEffect, useRef, useState} from "react";
import {Default} from "../../../../../utils/GoogleMapsUtils";
import getMapZoom from "../../../../../utils/getMapZoom";
import {useFormikContext} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Rings} from "react-loader-spinner";
import {Plus, X} from "react-feather";
import useAddressPredictions from "../../../../../Hooks/useAddressPredictions";
import AnimatedDropdown, {AnimatedDropdownList, AnimatedDropdownListItem} from "../../../../Parts/AnimatedDropdown";
import CloseOutside from "../../../../Parts/CloseOutside";
import useLoadGoogleMaps from "../../../../../Hooks/useLoadGoogleMaps";
import {PiWarningCircleLight} from "react-icons/pi";
import {FaLock} from "react-icons/fa";
import {TooltipHelper} from "../../../../Parts/Tooltips";

const DisplayRangeInput = ({rangeRef, rangeValueRef, queryRef, isNewLocation}) => {

	const handleChangeRange = (event) => {
		let r = event.target.value;

		rangeRef.current.value = event.target.value

		rangeValueRef.current.innerHTML = `${event.target.value} km`

		rangeRef.current.style.backgroundSize = `${event.target.value * 100 / 200}% 100%`;

		if(window.GoogleLocationMap && window.GoogleLocationMapCircle) {

			window.GoogleLocationMap.setZoom(getMapZoom(r));

			window.GoogleLocationMapCircle.setRadius(r * 1000);

		}

	};

	return (
		<Form.Group className="w-100 d-flex align-items-center">
			<div className="w-100 location-range-wrapper position-relative">
				<input style={{backgroundSize: '0'}} disabled={!isNewLocation && queryRef} type="range" ref={rangeRef} className="location-range" min={1} max={200} defaultValue={0} onChange={(event) => queryRef ? handleChangeRange(event) : ''} />
			</div>
			<Form.Label className="d-flex mb-0 me-3 w-25 justify-content-center"><span className="text-muted font-md" ref={rangeValueRef}>0 km</span></Form.Label>

		</Form.Group>
	)
}

const DisplayPredictions = ({predictions, loading, queryRef, setLocation, setOpen, open}) => {

	const handleSetPrediction = async (prediction) => {

		setOpen(false);

		queryRef.current.value = prediction.description;

		setLocation(prediction)
	}

	return(
		<AnimatedDropdown className={'w-100'} isOpen={(open && predictions.length > 0)}>
			{loading ?
				<Rings height="20" width="20" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> :
				<AnimatedDropdownList>
					{predictions.map((prediction, index) =>
						<AnimatedDropdownListItem key={index} handleClick={() => handleSetPrediction(prediction)}>{prediction.description}</AnimatedDropdownListItem>)}
				</AnimatedDropdownList>}
		</AnimatedDropdown>
	)
}

const LocationsList = ({isNewLocation, setCurrentLocationMap, setDefaultMap}) => {

	const { t } = useTranslation();

	const {values, setFieldValue} = useFormikContext();

	if(values.locations.length === 0){
		return <></>
	}

	const locationRemove = async (name) => {
		if(values.locations.length <= 1 || isNewLocation){
			return
		}
		const newLocationsArray = values.locations.filter(el => el.name !== name);
		await setFieldValue('locations', newLocationsArray)
		setCurrentLocationMap({lat: newLocationsArray[newLocationsArray.length - 1].lat, lng: newLocationsArray[newLocationsArray.length - 1].lon, radius: newLocationsArray[newLocationsArray.length - 1].radius})
	}

	return(
		<div className="mt-4 d-flex flex-column">
			<h3 className="mb-3 title">{t('profile.edit.tabs.common.locations.locationsList.title')}</h3>
			<div className="d-flex flex-wrap">
				{values.locations.map((location, index) => {
					return(
						<div className="d-flex me-2" key={index}>
							<div className="d-flex align-items-center locations-list">
								<div className="d-flex align-items-center">
									<p className="cursor-pointer font-family-open-sans" onClick={() => isNewLocation ? '' : setCurrentLocationMap({lat: location.lat, lng: location.lon, radius: location.radius})}>
										{location.name} +{location.radius} km
									</p>
								</div>
								<X className="ms-1" size={16} onClick={() => locationRemove(location.name)} />
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const TabLocations = () => {

	const { isLoaded, error } = useLoadGoogleMaps();

	const {values, setFieldValue} = useFormikContext();

	const { t } = useTranslation();

	const mapRef = useRef();

	const queryRef = useRef();

	const rangeRef = useRef();

	const rangeValueRef = useRef()

	const [currentLocationMap, setCurrentLocationMap] = useState({
		lat: values.locations.length === 0 ? '' : values.locations[0].lat,
		lng: values.locations.length === 0 ? '' : values.locations[0].lon,
		radius: values.locations.length === 0 ? 12 : values.locations[0].radius
	})

	const [defaultMap, setDefaultMap] = useState(true)

	const[isNewLocation, setIsNewLocation] = useState(false);

	const [open, setOpen] = useState(false);

	const [locationAvailable, setLocationAvailable] = useState(true)

	const [location, setLocation] = useState({
		description: "Niesułków",
		place_id: "ChIJRyjmCm7PG0cRS7GWRy0HmPM",
		reference: "ChIJRyjmCm7PG0cRS7GWRy0HmPM"
	});

	const {setAddress, address, predictions, loading} = useAddressPredictions();

	const [cords, setCords] = useState(
		{
			lat: 19,
			lng: 50
		}
	);

	const handleKeyDown = (event) => {

		if(event.target.value !== '' && address !== event.target.value) {

			setAddress(event.target.value);

		}

	};

	const handleSaveLocation = (e) => {
		e.preventDefault()
		setFieldValue('locations', values.locations.concat({
			name: location.description,
			radius: rangeRef.current.value,
			lat: cords.lat,
			lon: cords.lng
		}))
		queryRef.current.value = '';
		setIsNewLocation(false);
		setDefaultMap(false)
	};

	useEffect(() => {
		if(mapRef.current !== null && window.google && defaultMap && isLoaded) {
			window.GoogleLocationMap = new window.google.maps.Map(
				mapRef.current,
				{
					mapId: Default.mapId,
					zoom: getMapZoom(currentLocationMap.radius),
					center: {
						lat: values.locations.length !== 0 ? values.locations[values.locations.length - 1].lat : Default.center.lat,
						lng: values.locations.length !== 0 ? values.locations[values.locations.length - 1].lng : Default.center.lng
					},
					disableDefaultUI: true
				}
			);

		}
		if(window.GoogleLocationMap) {

			const geocoder = new window.google.maps.Geocoder();

			geocoder.geocode(
				{
					'placeId': location.place_id
				},
				function(responses, status) {

					if(status === 'OK') {

						let map = window.GoogleLocationMap;

						setCords(
							{
								lat: responses[0].geometry.location.lat(),
								lng: responses[0].geometry.location.lng()
							}
						);

						if(queryRef.current !== null && queryRef.current.value.length === 0){
							map.setCenter(
								new window.google.maps.LatLng(
									currentLocationMap.lat || 52.2297,
									currentLocationMap.lng || 21.0122
								)
							);
						}
						else{
							map.setCenter(
								new window.google.maps.LatLng(
									responses[0].geometry.location.lat(),
									responses[0].geometry.location.lng()
								)
							);
						}

						values.locations.map(el => {
							let positionValue = {
								lat: el.lat,
								lng: el.lon
							}

							window.GoogleLocationMapCircle = new window.google.maps.Circle({
								strokeColor: "#aa60f9",
								strokeOpacity: 0.8,
								strokeWeight: 1,
								fillColor: "#5100b9",
								fillOpacity: 0.2,
								map,
								center: positionValue,
								radius: el.radius * 1000,
								position:positionValue
							});
						})

						if(predictions.length !== 0){
							let positionValue = {
								lat: responses[0].geometry.location.lat(),
								lng: responses[0].geometry.location.lng()
							}
							setIsNewLocation(true)
							window.GoogleLocationMapCircle = new window.google.maps.Circle({
								strokeColor: "#aa60f9",
								strokeOpacity: 0.8,
								strokeWeight: 1,
								fillColor: "#5100b9",
								fillOpacity: 0.2,
								map,
								center: positionValue,
								radius: rangeRef * 1000,
								position:positionValue
							});
						}

						map.setZoom(getMapZoom(currentLocationMap.radius));

					}
				});
		}

	}, [location, currentLocationMap, isLoaded])

	useEffect(() => {

		if(values.working_mode !== null && values.working_mode?.findIndex(el => el === '0') !== -1){
			setLocationAvailable(false);
		}
		else{
			setLocationAvailable(true)
		}
	}, [values])

	if(error){
		return(
			<div className={'bg-gray d-flex flex-column align-items-center pt-5 pb-5 mt-3 rounded-3'}>
				<PiWarningCircleLight size={36} className={'mb-1'} />
				<p className='m-0 font-family-open-sans'>
					Error while loading maps
				</p>
			</div>
		)
	}

	return (
		<Row>
			<Col className={'ms-0 position-relative'}>
				<h3 className={'title'}>{t(`profile.profile.tabs.common.locations.types.free`)}</h3>
				<Form.Group>
					<Row className={'form-control-with-loader flex-column flex-sm-row'}>
						<Col className="d-flex w-100 mb-sm-0 mb-3">
							<CloseOutside handleClose={() => setOpen(false)} className="position-relative w-100">
								<Form.Control disabled={!locationAvailable} onFocus={() => setOpen(true)} className="pt-3 pb-3 ps-3" ref={queryRef} placeholder={t('profile.edit.tabs.common.locations.placeholder')} type="text" onKeyDown={(event) => handleKeyDown(event)} required/>
								<DisplayPredictions predictions={predictions} loading={loading} queryRef={queryRef} setLocation={setLocation} setOpen={setOpen} open={open} />
								{!locationAvailable &&
									<TooltipHelper className={'position-absolute top-50 end-0 translate-middle-y me-3'} icon={FaLock}>
										<p className={'mb-0'}>
											You have selected a remote location
										</p>
									</TooltipHelper>
								}
							</CloseOutside>
						</Col>
						<Col className="d-flex mt-4 mt-md-0 mb-4 mb-md-0">
							<DisplayRangeInput rangeRef={rangeRef} rangeValueRef={rangeValueRef} queryRef={queryRef} isNewLocation={isNewLocation}/>
						</Col>
						<button disabled={loading || location.description === 'Niesułków' || queryRef.current.value.length === 0 || !locationAvailable} type="button" className="btn btn-primary btn-circle md flex-shrink-0 ms-2" onClick={handleSaveLocation} title={t('profile.edit.tabs.common.locations.add')}>
							{loading ?
								<Rings height="30" width="30" color="#474747" radius="6" ariaLabel="rings-loading"/>
								:
								<Plus />
							}
						</button>
					</Row>
				</Form.Group>
				{locationAvailable && <LocationsList isNewLocation={isNewLocation} setCurrentLocationMap={setCurrentLocationMap} setDefaultMap={setDefaultMap}/>}
			</Col>
			{locationAvailable &&
				<Col md={12}>
					<div className={'profile-display-map'}>
						<div className={'map mt-4'} id={'map'} ref={mapRef}></div>
					</div>
				</Col>
			}
		</Row>
	);
};

export default TabLocations;
