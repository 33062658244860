import {Rings} from "react-loader-spinner";
import {Star} from "react-feather";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import {useLocation} from "react-router-dom";
import useWatchlist from "../../../Hooks/ReduxHooks/useWatchlist";

const ObserverButton = ({className, size, isText, uid}) => {

	const {t} = useTranslation();

	const {handleObserve, observed, observedLoading} = useWatchlist(uid);

	const {user, isIOS} = useUser();

	let [watchlist, setWatchlist] = useState(false)

	const location = useLocation();

	useEffect(() => {

		location.pathname === '/observations' ? setWatchlist(true) : setWatchlist(false);

	}, [location.pathname]);

	if(user && user.id === uid){

		return ;

	}

	return (
		<>
			{isText ?
				<div onClick={() => handleObserve(uid)}>
					{observedLoading ? <>{t('parts.misc.observe.sending')}</> : (observed || watchlist ? t('parts.misc.observe.dontObserve') : t('parts.misc.observe.observe'))}
				</div>
				:
				<button title={observed ? 'Przestań obserwować' : 'Obserwuj'} className={!observedLoading && observed ? `${className} observe-wrapper observed icon-button` : `${className} observe-wrapper  icon-button `} onTouchStart={() => isIOS ? handleObserve(uid) : undefined} onClick={() => !isIOS ? handleObserve(uid) : undefined} disabled={observedLoading}>
					<div className={`observe-icon ${observed ? 'animation-active' : ''}`}>
						{observedLoading ?
							<Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>
							:
							<Star className={observed || watchlist ? 'star-icon' : ''} size={size}/>}
					</div>
				</button>
			}

		</>
	);

};

export default ObserverButton;