import React from "react";
import placeholderImage from "../../../assets/images/placeholder.jpg";
import useMessage from "../../../Hooks/ReduxHooks/useMessage";
import timestampToDate from "../../../utils/timestampToDate";
import MoreOptions from "../../Parts/Misc/MoreOptions";
const UserLogoContent = ({url}) => {

	const handleError = (e) => {
		e.target.src = placeholderImage;
	}

	return(
		<div className="avatar rounded-5">
			{url ? <img src={url} alt="Placeholder Image" onError={handleError} /> : <img src={placeholderImage} alt="Placeholder Image" />}
		</div>
	)
}

const Item = ({conversation}) => {

	const {handleSetChatID, handleSetConversationHistory, chatID} = useMessage();

	const handleClick = () => {
		if(chatID !== conversation.receiverId){
			handleSetChatID(conversation.receiverId)
			handleSetConversationHistory({count: 0, last: null, items: []})
		}
	}

	return(
		<div className={`d-flex align-items-center user cursor-pointer justify-content-between ${conversation.receiverId === chatID && 'active'}`} onClick={handleClick}>
			<div className={'d-flex align-items-center w-75'}>
				<UserLogoContent url={conversation.receiverAvatar} />
				<div className="d-flex flex-column ms-2 text-limit">
					<span className="font-xxs text-gray">
						{conversation.lastMessage ? timestampToDate(Number(conversation.lastMessage), localStorage.getItem('i18nextLng')) : ''}
					</span>
					<span className={`font-weight-md text-limit ${conversation.unreadCount > 0 ? 'unread' : ''}`}>{conversation.receiverName !== null ? conversation.receiverName.slice(0, 25) : 'No Name'}</span>
				</div>
			</div>
			<div className={'me-2 position-relative'}>
				{conversation.receiverId !== 'JOBOT2a1-f8ae-401c-9c7c-d8ec17488229' && <MoreOptions item={chatID} options={['ArchiveConversation']} />}
			</div>
		</div>
	)
}

export default Item;