import React, {useRef} from "react";
import {useTranslation} from 'react-i18next';
import TopBar from '../../TopBar';
import useTabs from '../../Common/useTabs';
import TabBasic from './Content/TabBasic';
import TabSkills from './Content/TabSkills';
import TabRequirements from './Content/TabRequirements';
import TabCharacter from './Content/TabCharacter';
import TabInterest from './Content/TabInterest';
import TabExperience from './Content/TabExperience';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Gallery from '../Gallery';
import {Loader, LoaderOverlay} from '../../../Parts/Loaders';
import UnsavedChangesPopup from '../../../Parts/Misc/UnsavedChangesPopup';
import useApi from "../../../../Hooks/useApi";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import ProfileActions from "./Common/ProfileActions";
import useError from "../../../../Hooks/ReduxHooks/useError";
import {Element} from "react-scroll";
import {Container} from "react-bootstrap";
import FloatingButtons from "../../../Parts/FloatingButtons";
import TabLocations from "./Content/TabLocations";

export const Profile = ({data, setData, loading, setLoading}) => {

	const {handleSetIndexStatusValue} = useError();

	const {t} = useTranslation();

	const containerRef = useRef();

	const {user, handleSetUser} = useUser();

	/**
	 *
	 * Tabs configuration
	 *
	 */

	const ConfigurationFreelance = [

		{
			key: 'basic',
			section_id: 'basic',
			title: t('profile.profile.tabs.profileTab.index.navLinks.aboutMe'),
			components: <TabBasic type={'freelance'}/>
		},
		{
			key: 'skills',
			section_id: 'skills',
			title: t('profile.profile.tabs.profileTab.index.navLinks.skills'),
			components: <TabSkills/>
		},
		{
			key: 'experience',
			section_id: 'requirements',
			title: t('profile.profile.tabs.profileTab.index.navLinks.experience'),
			components: <TabExperience/>
		},
		{
			key: 'character',
			section_id: 'character',
			title: t('profile.profile.tabs.profileTab.index.navLinks.character'),
			components: <TabCharacter/>

		},
		{
			key: 'interests',
			section_id: 'interests',
			title: t('profile.profile.tabs.profileTab.index.navLinks.interests'),
			components: <TabInterest/>
		},
		{
			key: 'requirements',
			section_id: 'experience',
			title: t('profile.profile.tabs.profileTab.index.navLinks.requirements'),
			components: <TabRequirements/>
		},
		{
			key: 'locations',
			section_id: 'locations',
			title: t('profile.profile.tabs.profileTab.index.navLinks.locations'),
			components: <TabLocations type="freelance"/>
		},
		{
			key: 'premium',
			section_id: 'premium',
			title: <Link to="/premium" className="btn btn-primary text-uppercase text-nowrap">Get Premium</Link>,
			components: <></>,
			isLink: true
		}

	];

	const Configuration = ConfigurationFreelance;

	if(window.innerWidth < 991) {
		Configuration.unshift({
			key: 'gallery',
			section_id: 'gallery',
			title: 'Galeria',
			components: <Gallery/>
		});
	}

	const {Tabs} = useTabs(Configuration, window.innerWidth < 991 ? 'gallery' : 'basic');

	const {request} = useApi();

	/**
	 *
	 * handle save profile
	 *
	 * @param values
	 */

	const handleSubmitFunction = (values) => {
		setLoading(true);

		delete values.contact_methods.visible;
		request({
			url: `/profile/details`,
			method: 'post',
			body: values,
			onSuccess: data => {
				setData(data);
				handleSetUser({...user, percent: data.percent});
				handleSetIndexStatusValue('pending');
			},
		}).finally(() => {
			setLoading(false);
		});

	};

	const Schema = {
		name: Yup.string().required(t('profile.profile.edit.schemaValidation.name.required')).max('100',t('profile.profile.edit.schemaValidation.name.max')),
		about: Yup.string().required(t('profile.profile.edit.schemaValidation.about.required')).max('5000',t('profile.profile.edit.schemaValidation.about.max')),
		contact_methods: Yup.object().shape({
			direct: Yup.boolean().test('hasTrueOrOtherMethod', t('profile.profile.edit.schemaValidation.applicationWays.oneOf'), function (value) {
				return (value === true || this.parent.quick === true || this.parent.email !== null || this.parent.phone !== null);
			}),
			email: Yup.string().nullable()
				.test('hasValue', t('profile.profile.edit.schemaValidation.applicationWays.oneOf'), function (value) {
					return this.parent.direct === true || this.parent.phone !== null || this.parent.quick === true || value !== null;
				})
				.test('isEmail', t('profile.profile.edit.schemaValidation.applicationWays.email.correct'), function (value) {
					if (value !== null && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
						return false;
					}
					return true;
				}),
			phone: Yup.string()
				.nullable()
				.test('hasValue', 'You must provide a phone number or choose another way of contact', function (value) {
					return this.parent.direct === true || this.parent.email !== null || this.parent.quick === true || value !== null;
				})
				.test('isPhoneNumber', 'Phone number is incorrect', function (value) {
					if (value) {
						const cleanedValue = value.replace(/[^0-9]/g, '');

						const isValid = cleanedValue.length === 10 || cleanedValue.length === 11;

						return isValid;
					}
					return true;
				})
		}),
		categories: Yup.array()
			.min(1, t('profile.profile.edit.schemaValidation.category.required')),
		salary_from: Yup.number()
			.typeError('Salary from must be a number')
			.positive('Salary from must be greater than zero'),
		salary_to: Yup.number()
			.typeError('Salary to must be a number')
			.positive('Salary to must be greater than zero')
			.test(
				'is-greater-than-salary-from',
				'Salary to must be greater than Salary from',
				function(value) {
					const { salary_from } = this.parent;

					return (salary_from === undefined || salary_from === null || salary_from === '') || (value === undefined || value === null || value === '' || value > salary_from);
				}
			),
	}

	if(!data) {
		return <Container><Loader/></Container>;
	}

	return (
		<>
			<TopBar title={t('profile.profile.edit.profileTab.title')} desc={t('profile.profile.edit.profileTab.description')} percentPopup={true} radius={window.innerWidth > 991}/>
			<div ref={containerRef}>
				{loading && <LoaderOverlay/>}
				<Formik validateOnChange={false} validateOnBlur={false} initialValues={data} onSubmit={(values) => handleSubmitFunction(values)} enableReinitialize={true} validationSchema={Yup.object().shape(Schema)}>
					{({dirty, isValid, handleSubmit}) => (
						<Element name="profile-edit-basic-scroll">
							<form onSubmit={handleSubmit} aria-disabled={loading} className={'profile-edit-basic position-relative'} id="profile-edit-basic">
								{dirty &&
									<UnsavedChangesPopup when={dirty} handleSubmit={handleSubmit} text={t('parts.misc.unsavedChangesPopup.title')} isValid={isValid} />
								}
								<Tabs actionComponent={<ProfileActions handleSubmit={(values) => handleSubmit(values)}/>}/>
								<FloatingButtons data={data} containerRef={containerRef} />
							</form>
						</Element>
					)}
				</Formik>
			</div>
		</>
	);

};

export default Profile;
