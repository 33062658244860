import {useFormikContext} from "formik";
import {firstLetterUpper} from "../../../../../utils/AppUtils";

const ProfileActions = ({handleSubmit}) => {

	const {resetForm, values, isValid, dirty, errors} = useFormikContext()

	return(
		<div className="mt-5 profile-actions">
			<div className={'d-flex'}>
				<button className="btn btn-gray-ccc font-xl pt-3 pb-3 pe-4 ps-4 text-white action-button" type="button" onClick={() => resetForm()}>Cancel</button>
				<button disabled={!dirty} className='btn btn-primary font-xl pt-3 pb-3 pe-4 ps-4 ms-2 action-button' type='submit' onClick={() => handleSubmit(values)}>Save</button>
			</div>
			{Object.keys(errors).length !== 0 &&
				<div className="mt-3">
					{Object.keys(errors).map((key, index) => (
						<>
							{key === 'contact_methods' ?
								<>
									{Object.keys(errors.contact_methods).map((contactKey, index) => (
										<p key={index} className={'error-message text-left mb-1'}>
											{firstLetterUpper(contactKey)}: {errors.contact_methods[contactKey]}
										</p>
									))}
								</>
								:
								<p key={index} className={'error-message text-left mb-1'}>
									{firstLetterUpper(key)}: {errors[key]}
								</p>
							}
						</>
					))}
				</div>
			}
		</div>
	)
}

export default ProfileActions;