import Item from '../Browse/Item/Item';
import {RelatedProducts} from 'react-instantsearch';
import React from 'react';
import './Assets/related.scss';

export const RelatedItems = ({id}) => {

	const SimilarItem = ({item}) => {
		return (
			<>
				<Item item={item} handleClick={() => {}}/>
			</>
		)
	}

	return (
		<RelatedProducts
			objectIDs={[`user-${id}`]}
			itemComponent={SimilarItem}
			headerComponent={() => <h2 className={'mt-5'}>Looking similar</h2>}
			limit={8}
		/>
	)

}