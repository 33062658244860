import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
import useApi from '../../../Hooks/useApi';
import useUser from '../../../Hooks/ReduxHooks/useUser';
import useConfig from '../../../Hooks/ReduxHooks/useConfig';
import DisplayRepeater from "./DisplayRepeater";

const DisplayCategories = ({ categories, onChange, onRemove }) => {
	const [isBlocked, setIsBlocked] = useState({ status: false, text: '', getPremium: false });
	const { t } = useTranslation();
	const { user } = useUser();
	const { config, plans } = useConfig();
	const categoriesConfig = config?.categories?.items;

	const freeLimit = plans.free.limits.tags;
	const premiumLimit = plans.premium.limits.tags;

	const fetchHints = async (query) => {
		let filteredResults = [];

		if (query) {
			filteredResults = categoriesConfig.filter(item =>
				item.title.toLowerCase().includes(query.toLowerCase())
			).map(item => ({
				id: item.slug,
				name: item.title
			}));
		} else {
			filteredResults = categoriesConfig.map(item => ({
				id: item.slug,
				name: item.title
			}));
		}

		return filteredResults;
	};

	const assignHint = (item, finished) => {
		onChange(item);
		finished();
	};

	const handleRemove = (item, finished) => {
		onRemove(item);
		finished();
	};

	let items = [];

	categories.forEach((category) => {
		items.push({
			id: category,
			name: t(`config.appConfig.job.categories.${category}`)
		});
	});

	useEffect(() => {
		if (categories.length >= (user.plan_id === 'free' ? freeLimit : premiumLimit)) {
			setIsBlocked({
				status: true,
				text: user.plan_id === 'free' ? 'Categories limit reached in free plan 1/1' : 'Categories limit reached in premium plan 3/3',
				getPremium: user.plan_id === 'free'
			});
		}
		else{
			setIsBlocked({
				status: false,
				text: '',
				getPremium: false
			});
		}
	}, [categories]);

	return (
		<div className="display-categories">
			<DisplayRepeater assignHint={assignHint} handleRemove={handleRemove} fetchHints={fetchHints} title={`${t('profile.profile.basic.categoryName')} - ${categories.length}/${user.plan_id === 'free' ? freeLimit : premiumLimit}`} items={items} section={'categories'} ownValue={false} isBlocked={isBlocked} />
		</div>
	);
};

const DisplayTags = ({ tags, onChange, onRemove }) => {
	const [isBlocked, setIsBlocked] = useState({ status: false, text: '', getPremium: false });
	const { t } = useTranslation();
	const { user, lang } = useUser();
	const { request } = useApi();

	const { plans } = useConfig();

	const freeLimit = plans.free.limits.categories;
	const premiumLimit = plans.premium.limits.categories;

	const fetchHints = async (query) => {
		const response = await request({
			method: 'get',
			url: `/profile/hints?section=category&query=${query}&lang=${lang}`
		});

		if (response) {
			return response.map(item => ({
				id: item.id,
				name: item.hint
			}));
		}
		return [];
	};

	const assignHint = (item, finished) => {
		onChange(item);
		finished();
	};

	const handleRemove = (item, finished) => {
		onRemove(item);
		finished();
	};

	let items = [];

	tags.forEach((tag, id) => {
		items.push({
			id: id,
			name: tag
		});
	});

	useEffect(() => {
		if (tags.length >= (user.plan_id === 'free' ? freeLimit : premiumLimit)) {
			setIsBlocked({
				status: true,
				text: user.plan_id === 'free' ? 'Tag limit reached in free plan 1/1' : 'Tag limit reached in premium plan 5/5',
				getPremium: user.plan_id === 'free'
			});
		}
		else{
			setIsBlocked({
				status: false,
				text: '',
				getPremium: false
			});
		}
	}, [tags]);

	return (
		<>
			<DisplayRepeater assignHint={assignHint} handleRemove={handleRemove} fetchHints={fetchHints} title={`${t('profile.profile.basic.tagsName')} - ${tags.length}/${user.plan_id === 'free' ? freeLimit : premiumLimit}`} items={items} section='tags' isBlocked={isBlocked} />
		</>
	);
};

const Taxonomy = () => {
	const { values, setFieldValue } = useFormikContext();

	return (
		<div className="mb-5">
			<DisplayCategories categories={values.categories} onChange={(item) => setFieldValue('categories', values.categories.concat(item.id))} onRemove={(item) => setFieldValue('categories', values.categories.filter((i) => i !== item.id))}/>
			<DisplayTags tags={values.tags} onChange={(item) => setFieldValue('tags', values.tags.concat(item.name))} onRemove={(item) => setFieldValue('tags', values.tags.filter((i) => i !== item.name))}/>
		</div>
	);
};

export default Taxonomy;
