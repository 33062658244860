import React, {useRef, useState} from "react";
import useUser from '../../Hooks/ReduxHooks/useUser';
import BecomeSeller from './Parts/BecomeSeller';
import { Stats} from 'react-instantsearch';
import Filters from './Filters/Filters';
import AlgoliaConfig, {statsTranslations} from '../../Config/AlgoliaConfig';
import ActiveFilters from './Parts/ActiveFilters';
import SortBy from './Parts/Sort';

const BrowseHeader = () => {

	const BrowseHeaderRef = useRef();

	const {isMobile} = useUser();

	return (
		<div>
			<div className={'browse-header'} ref={BrowseHeaderRef}>
				<div className="d-flex align-items-center">
					<Filters/>
				</div>
				{!isMobile && <BecomeSeller/>}
			</div>
			<div className="d-flex align-items-center mt-3 flex-md-column mb-3 mb-lg-0 flex-column flex-lg-row">
				<ActiveFilters/>
				<div className={'d-flex ms-md-auto align-items-center justify-content-md-center align-self-md-center'}>
					<Stats translations={statsTranslations}/>
					<div className={'ms-2'}>
						<SortBy items={AlgoliaConfig.sorting}/>
					</div>
				</div>
			</div>
			{isMobile && <BecomeSeller/>}
		</div>
	);
};

export default BrowseHeader;
