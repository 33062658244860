import axios from 'axios';
import {fetchAuthSession} from 'aws-amplify/auth';
import i18n from 'i18next';
import ErrorConfig from "./Config/ErrorConfig";
import Notification from "./utils/NotificationUtils";
import {signOut} from 'aws-amplify/auth';
const errors = ErrorConfig.errors;

const getInitialHeaders = ({ token }) => {
	return {
		'Content-Type': 'application/json',
		'Authorization': token,
		'Accept-Language': localStorage.getItem('i18nextLng')
	};
};

const createApiInstance = async (config = {}, headers = {}) => {
	let accessToken = "";

	try {
		const { tokens } = await fetchAuthSession();
		accessToken = tokens.accessToken;
	} catch (err) {
		await signOut();
		console.log("Auth session error: ", err);
	}

	const initialHeaders = getInitialHeaders({ token: accessToken });

	const initialConfig = {
		baseURL: process.env.REACT_APP_API_HOST,
		timeout: 5000,
		headers: { ...initialHeaders, ...headers }
	};

	return axios.create({ ...initialConfig, ...config });
};

const callToAPI = async ({url, method, body = null, onError = () => {}, onSuccess = () => {}, displayNotifications = true, config = {}, headers = {}
}) => {
	const instance = await createApiInstance(config, headers);

	try {
		const res = await instance[method](url, body);
		onSuccess(res.data);
		return res.data;
	} catch (err) {
		if (err.code !== 'ERR_CANCELED') {
			if (err.response && err.response.data && displayNotifications) {
				if (err.response.status === 422) {
					Notification.error(err.response.data.message, `Error ${err.response.status}!`);
				} else {
					Notification.error(i18n.t(`config.errorConfig.errors.${errors[err.response.status]}`), `Error ${err.response.status}!`);
				}
			} else {
				if (err.message !== undefined && displayNotifications) {
					Notification.error(err.message, 'error');
				}
			}
			onError(err);
		}
	}
};

export default callToAPI;
