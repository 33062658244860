import React, {useState} from "react";
import './Item.scss';
import ItemFooter from './ItemFooter';
import useItem from '../../../Hooks/useItem';
import {Col, Row} from 'react-bootstrap';
import Share from '../../Parts/Misc/Share';
import MoreOptions from '../../Parts/Misc/MoreOptions';
import {Link} from "react-router-dom";
import ObserverButton from "../Parts/ObserverButton";
import placeholderImage from '../../../assets/images/placeholder.jpg'
import {ReactComponent as Icon} from '../../../assets/svg/premium/thumbnail.svg'
import {Rings} from "react-loader-spinner";
const Item = ({item, children, handleClick}) => {

	const {itemLink} = useItem(item);

	const [isLoading, setIsLoading] = useState(true);

	const handleImageLoad = () => {
		setIsLoading(false);
	};

	const handleImageError = () => {
		setIsLoading(false);
	};

	return (
		<div className={`app-browse-loop-item ${item.plan_id === 'premium' ? 'is-premium' : 'is-normal'}`}>
			<div className={'thumbnail'}>
				{isLoading &&
					<div className={'position-absolute top-50 start-50 translate-middle'}>
						<Rings height="125" width="125" color="#5100b9" radius="6" visible={true} ariaLabel="rings-loading"/>
					</div>
				}
				<img src={item.avatar || placeholderImage} alt={item.name} onLoad={handleImageLoad} onError={handleImageError} style={isLoading ? { display: 'none' } : {}}/>
				<div className={'video-preview cursor-pointer'} onClick={() => handleClick(item)}></div>
				<Row className={'top-icons'}>
					<Col xs={8} className={'float-start d-flex align-items-start'}>
						<ObserverButton size={24} isText={false} uid={item.uuid}/>
						<Share size={24} item={item}/>
					</Col>
					<Col>
						<MoreOptions item={item} options={['Share', 'Observe', 'ShowSimilar', 'NoMore', 'Report', 'Hide', 'Close']}/>
					</Col>
				</Row>
				<div className={'title text-limit'}>
					<Link to={itemLink()} className={'d-flex align-items-center'}>
						{item.plan_id === 'premium' && <Icon width={18} height className={'me-1'} />}
						{item.name}
					</Link>
				</div>
			</div>
			<ItemFooter item={item}/>
			{children}
		</div>
	);

};

export default Item;
