import React, {useState} from "react";
import {useSortBy} from 'react-instantsearch';
import {Col, Form, Row} from 'react-bootstrap';
import CloseOutside from "../../Parts/CloseOutside";
import AnimatedDropdown from "../../Parts/AnimatedDropdown";
import {IoMdArrowDropdown} from "react-icons/io";

const SortBy = (props) => {

	const { currentRefinement, options, refine } = useSortBy(props);

	const [isActive, setIsActive] = useState(false);

	const activeOption = options.filter(el => el.value === currentRefinement)[0];

	const handleClickOption = (value) => {
		refine(value)
	}

	return (
		<div className={'sort-by'}>
			<button type={'button'} onClick={() => setIsActive(prevState => !prevState)} className={'sort-by-button font-sm font-weight-sm'}>
				{activeOption.label}
				<IoMdArrowDropdown size={18} className={`rotate-animation ${isActive ? 'active' : ''}`} />
			</button>
			<div className="position-relative">
				<CloseOutside handleClose={() => setIsActive(false)}>
					<AnimatedDropdown isOpen={isActive} className="w-100 mt-2">
						<ul>
							{options.map((option, index) => (
								<li key={index} onClick={() => handleClickOption(option.value)}>
									{option.label}
								</li>
							))}
						</ul>
					</AnimatedDropdown>
				</CloseOutside>
			</div>
		</div>

	);
}

export default SortBy;