import React from "react";
// import ChangePasswordForm from './Password/ChangePasswordForm';
import {Page} from '../../Layout/Page';
import {useTranslation} from 'react-i18next';
import useTabs from '../Common/useTabs';
import TopBar from '../TopBar';
import ProfileReset from './ProfileReset';
import Logout from './Logout';
import Remove from './Remove';
import {Col, Container, Row} from 'react-bootstrap';
import Account from "./Account/Account";
import AccountType from "./AccountType/AccountType";
import './Assets/settings.css'
import './Assets/settings-media-query.css'
const Index = () => {

	const { t } = useTranslation();

	const SettingsWrapper = ({ComponentMain, ComponentInformation}) => {
		return(
			<Container>
				<Row className='w-100'>
					<Col md={ComponentInformation ? 6 : 12} className={`${ComponentInformation ? 'settings-wrapper-left' : ''} pe-0 pe-md-4 pb-4 pb-md-0 ps-0`}>
						{ComponentMain}
					</Col>
					{ComponentInformation &&
						<Col md={6} className="ps-0 ps-md-4 pt-4 pt-md-0 font-lg justify-content-start">
							{ComponentInformation}
						</Col>
					}
				</Row>
			</Container>

		)
	}

	const Information = ({text}) => {
		return(
			<p>
				{text}
			</p>
		)
	}

	const Configuration = [
		{
			key: 'account-type',
			section_id: 'account-type',
			title: 'Account Type',
			components: <SettingsWrapper ComponentMain={<AccountType />} ComponentInformation={null} />
		},
		{
			key: 'security',
			section_id: 'security',
			title: 'Security',
			components:
				<>
					<SettingsWrapper ComponentMain={<Account/>} ComponentInformation={<Information text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt maximus orci eu efficitur. Mauris non iaculis odio, scelerisque porta felis. Etiam non ultricies eros. Nunc scelerisque sollicitudin tortor, at sagittis sapien. Maecenas feugiat porta nibh, in vestibulum ante aliquet at. In egestas consequat massa. Sed tincidunt nisl neque, eget pellentesque sem finibus sit amet. Vestibulum nec tempor justo." />}/>
					{/*<SettingsWrapper ComponentMain={<Remove/>} ComponentInformation={<Information text={t('profile.profile.tabs.settingsTab.index.configuration.delete.information')} />} />*/}
				</>
		},
		{
			key: 'reset',
			section_id: 'reset',
			title: 'Hidden profiles',
			components: <SettingsWrapper ComponentMain={<ProfileReset/>} ComponentInformation={<Information text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt maximus orci eu efficitur. Mauris non iaculis odio, scelerisque porta felis. Etiam non ultricies eros. Nunc scelerisque sollicitudin tortor, at sagittis sapien. Maecenas feugiat porta nibh, in vestibulum ante aliquet at. In egestas consequat massa. Sed tincidunt nisl neque, eget pellentesque sem finibus sit amet. Vestibulum nec tempor justo.' />} />
		},
		{
			key: 'logout',
			section_id: 'logout',
			title: t('profile.profile.tabs.settingsTab.index.configuration.logout.title'),
			components: <SettingsWrapper ComponentMain={<Logout/>} ComponentInformation={<Information text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt maximus orci eu efficitur. Mauris non iaculis odio, scelerisque porta felis. Etiam non ultricies eros. Nunc scelerisque sollicitudin tortor, at sagittis sapien. Maecenas feugiat porta nibh, in vestibulum ante aliquet at. In egestas consequat massa. Sed tincidunt nisl neque, eget pellentesque sem finibus sit amet. Vestibulum nec tempor justo.' />} />
		},
	];

	const { Tabs } = useTabs(Configuration, 'account-type');

	return (
		<Page title={t('pageTitles.settings')}>
			<TopBar title={t('profile.profile.tabs.settingsTab.index.topBar.title')} desc={t('profile.profile.tabs.settingsTab.index.topBar.desc')} backButton={true} />
			<Container>
				<div className={'settings'}>
					<Tabs/>
				</div>
			</Container>
		</Page>
	);

};

export default Index;
